<script setup>
import Vue from "vue";
import { enforcement_data, fetchAllEnforcers, snackBarErrorMessage, snackBarSuccessMessage, snackBarInfoFunction } from "../buildings_business_logic.js";
import LabelledField from "@/components/utils/LabelledField.vue";
import apiCall from "@/utils/api.js";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { computed, onMounted, ref } from "vue";
import BackButton from "../../utils/BackButton.vue";
import Table from "../../utils/Table.vue";
import ViewItemSectionTitle from "../../utils/ViewItemSectionTitle.vue";
import NextButton from "@/components/utils/NextButton.vue";
import { fieldRequired, } from "@/utils/input_validation.js";

var numeral = require("numeral");
Vue.use(require("vue-moment"));
Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0");
});

const emit = defineEmits(['goBackClicked'])

const { snackbar_info } = snackBarInfoFunction()

const props = defineProps({
    building_application: {
        type: Object,
        default: null,
    },
    //   showGoBack: {
    //     type: Boolean,
    //     default: false,
    //   },
});

const loading = ref(false)
const loadingButton = ref(false)
const formEnforceBuilding = ref(null);
const generated_bills = ref([])
const createBillForEnforcementDialog = ref(false)

const enforcers_data_object = fetchAllEnforcers()
const enforcers_data = ref([])

const buildingDetails = computed(() => {
    let buildingDetails = {};
    buildingDetails = getBuildingDetails(props.building_application);

    return buildingDetails;
});


function getBuildingDetails(building_application) {
    const buildingDetails = {
        section1: {},
        section2: {},
    };
    buildingDetails.section1["Parcel Number"] = building_application.building.parcel_no;
    buildingDetails.section1["Subcounty"] = building_application.building.sub_counties.sub_county_name;
    buildingDetails.section1["Ward"] = building_application.building.ward.ward_name;
    buildingDetails.section1["Town"] = building_application.building.towns.town_name;
    buildingDetails.section1["Latitude"] = building_application.building.latitude;
    buildingDetails.section1["Longitude"] = building_application.building.longitude;

    buildingDetails.section2["Cost"] = building_application.building.cost;
    buildingDetails.section2["Unit of Measure"] = 'Sq Meters'
    buildingDetails.section2["Plinth Area"] = building_application.building.plinth_area
    buildingDetails.section2["Development Type"] = building_application.building.building_development_type.type_of_development
    buildingDetails.section2["Number of Floors"] = returnNumOfFloors()
    buildingDetails.section2["Approval Number"] = building_application.building.approval_number
    buildingDetails.section2["Physical Location/Lanmark"] = building_application.building.physical_address

    return buildingDetails;
}

async function submitEnforcementApp() {

    console.log("building_application123123")
    console.log(props.building_application)

    if (formEnforceBuilding.value.validate()) {
        loadingButton.value = true
        enforcement_data.building_application_id = props.building_application.id
        enforcement_data.sub_county_id = props.building_application.building.sub_county_id
        enforcement_data.ward_id = props.building_application.building.ward_id
        enforcement_data.town_id = props.building_application.building.town_id
        enforcement_data.fee_id = generated_bills.value[0].fee_id
        enforcement_data.quantity = generated_bills.value[0].quantity
        enforcement_data.uom = generated_bills.value[0].uom
        enforcement_data.sub_total = generated_bills.value[0].sub_total

        await apiCall({
            url: "/api/client/enforceBuildingApp",
            data: enforcement_data,
            method: "POST",
        })
            .then((resp) => {
                //resp.status
                console.log("resp submitBuildingApplication");
                console.log(resp);
                if (resp.status == "success") {
                    snackBarSuccessMessage("Building Enforcement Created successfully", 5000)
                    createBillForEnforcementDialog.value = false
                    setTimeout(function () {
                        emit('goBackClicked')
                    }, 2000)
                    // createBillForBpaDialog.value = false
                    // bill_items_array.value = resp.bill_items
                    // bill_object.value = resp.bill
                    // building_application_id.value = resp.building_application.id
                    // changeView('view_bill_view')
                    // scrollToTop()
                    //downloadBuildingSummaryDocument()

                }
                else if (resp.status == "error") {
                    snackBarErrorMessage(resp.message, 5000)

                }
                else {
                    snackBarErrorMessage("Error Occured, please contact admin.", 5000)
                    console.log("Response Error")
                    console.log(resp)

                }

                loadingButton.value = false


            })
            .catch((error) => {
                console.log("error enforceBuildingApp");
                console.log(error);
                snackBarErrorMessage(error, 5000)
                loadingButton.value = false

            });

    }

}

function returnEnforcementBill() {
    if (formEnforceBuilding.value.validate()) {
        loading.value = true

        apiCall({
            url: "/api/client/returnEnforcementBill",
            method: "GET",
        })
            .then((resp) => {
                console.log("returnEnforcementBill");
                console.log(resp);

                loading.value = false

                if (resp.status == "error") {
                    alert(resp.message)
                    return

                }

                if (resp.status == 'success') {
                    generated_bills.value = resp.bill_items
                    createBillForEnforcementDialog.value = true

                }

            })
            .catch((error) => {
                console.log("error enforceBuildingApp 1");
                console.log(error);
                snackBarErrorMessage(error, 5000)
                loading.value = false

            });

    }

}

function returnOwnerName() {

    if (props.building_application.building.building_owner.length > 0) {
        return props.building_application.building.building_owner[0].clients.customer_name

    } else {
        return "No Owner Found"
    }

}

function returnOwnerTel() {
    if (props.building_application.building.building_owner.length > 0) {
        return props.building_application.building.building_owner[0].clients.phone_number1

    } else {
        return "No Owner Found"
    }

}

function returnArchitectName() {
    if (props.building_application.building.building_architect.length > 0) {
        return props.building_application.building.building_architect[0].architects.first_name + ' ' + props.building_application.building.building_architect[0].architects.last_name

    } else {
        return "No Owner Found"
    }

}

function returnArchitectTel() {
    if (props.building_application.building.building_architect.length > 0) {
        return props.building_application.building.building_architect[0].architects.architect_number

    } else {
        return "No Owner Found"
    }

}

function returnPlinthArea() {
    return props.building_application.building.plinth_area

}

function returnTypeOfDevelopement() {
    return props.building_application.building.building_development_type.type_of_development

}

function returnNumOfFloors() {
    if (props.building_application.building.number_of_floors) {
        return props.building_application.building.number_of_floors

    }

    const plinth_areas_list = JSON.parse(props.building_application.building.plinth_areas_list)

    return plinth_areas_list.length

}

function getMyBillsSubTotal(my_bills) {
    let total = 0
    for (let i = 0; i < my_bills.length; i++) {
        total = total + parseFloat(my_bills[i].sub_total)

    }
    return total

}



onMounted(() => {
    console.log("props.building_application")
    console.log(props.building_application)

    enforcers_data_object.mutate(
        {},
        {
            onSuccess(enforcers_data_object) {
                enforcers_data.value = enforcers_data_object

            },
        }
    );

})

</script>

<template>
    <v-container fluid class="pa-0">
        <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
            :color="snackbar_info.color">
            <span>{{ snackbar_info.snackbar_message }}</span>
        </v-snackbar>

        <v-row>
            <v-col cols="12" md="1">
                <BackButton @click="$emit('goBackClicked')">
                    Back To Houses
                </BackButton>
            </v-col>
            <v-col cols="12" md="10">
                <div class="text-h6 text-center font-weight-bold primary--text">
                    Building Details
                </div>
            </v-col>
            <v-spacer />
        </v-row>

        <v-row justify-md="center">
            <v-col v-if="$mobileBreakpoint" cols="12">
                <Table :headers="smallScreenHouseDetails.tableHeaders"
                    :items="smallScreenHouseDetails.houseDetailsEnclosedInArray">
                </Table>
            </v-col>

            <template v-else>
                <v-col v-for="(houseDetailSection, sectionName, index) in buildingDetails" :key="sectionName" cols="12"
                    md="6">

                    <v-row v-if="index == 0">
                        <v-col cols="auto">
                            <ViewItemSectionTitle>Building Plan Details</ViewItemSectionTitle>
                        </v-col>
                    </v-row>

                    <v-simple-table dense class="building_application-details-table">
                        <tbody>
                            <tr v-for="(houseAttribute, attributeTitle) in houseDetailSection" :key="attributeTitle">
                                <td class="font-weight-bold">
                                    {{ attributeTitle }}
                                </td>
                                <td>
                                    <template v-if="attributeTitle !== 'Occupied'">
                                        {{ houseAttribute }}</template>
                                    <template v-else>
                                        <v-icon v-if="houseAttribute === true" color="success">mdi-check-circle</v-icon>
                                        <v-icon v-else color="error">mdi-close</v-icon>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </template>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <ViewItemSectionTitle>Enforce Buildings</ViewItemSectionTitle>
            </v-col>
        </v-row>

        <v-form ref="formEnforceBuilding" lazy-validation>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="Reason for Enforcement" required>
                        <v-textarea class="text-field-background" outlined dense required
                            v-model="enforcement_data.enforcement_reason" :rules="[fieldRequired]"
                            hide-details="auto"></v-textarea>
                    </LabelledField>

                </v-col>

            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="License Expiry Date" required>
                        <DatePicker :rules="[fieldRequired]" :textFieldProps="{
                            outlined: true,
                            dense: true,
                            class: 'text-field-background',
                            'hide-details': 'auto',
                        }" only-show-value-on-change v-model="enforcement_data.enforcement_date" />
                    </LabelledField>
                </v-col>

            </v-row>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="Select Enforcer" required>
                        <v-select :items="enforcers_data" item-text="name" item-value="id" outlined dense
                            class="text-field-background" required v-model="enforcement_data.enforcer"
                            :rules="[fieldRequired]" hide-details="auto"></v-select>
                    </LabelledField>
                </v-col>

            </v-row>

        </v-form>

        <v-row>
            <v-col cols="12" md="2">
                <v-btn class="text-none" color="success" type="submit" @click="returnEnforcementBill()"
                    :loading="loading">Enforce Building<v-icon right>mdi-police-badge</v-icon></v-btn>

            </v-col>
        </v-row>


        <!-- Create Finall Bill Dialog For Enforcement Starts Here -->
        <v-row justify="center">
            <v-dialog v-model="createBillForEnforcementDialog" max-width="600">

                <v-card>
                    <v-card-title class="primary white--text" align="center">
                        Bill Items Summary
                    </v-card-title>

                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        <b class="primary--text"> </b>
                                    </th>
                                    <th class="text-left">
                                        <b class="primary--text"> Description </b>
                                    </th>
                                    <th class="text-left">
                                        <b class="primary--text">Amount</b>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td></td>
                                    <td>Owner Name</td>
                                    <td>{{ returnOwnerName() }}</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Owner Tel Number</td>
                                    <td>{{ returnOwnerTel() }}</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Architect Name</td>
                                    <td>{{ returnArchitectName() }}</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Architect Tel Number</td>
                                    <td>{{ returnArchitectTel() }}</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Plinth Area</td>
                                    <td>{{ returnPlinthArea() }}</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Type of Development</td>
                                    <td>{{ returnTypeOfDevelopement() }}</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Num of Floors</td>
                                    <td>{{ returnNumOfFloors() }}</td>
                                </tr>

                            </tbody>

                        </template>
                    </v-simple-table>

                    <v-simple-table>
                        <template v-slot:default>

                            <thead>
                                <tr>
                                    <th class="text-left">
                                        <b class="primary--text">No.</b>
                                    </th>
                                    <th class="text-left">
                                        <b class="primary--text">Description</b>
                                    </th>
                                    <th class="text-left">
                                        <b class="primary--text">Amount</b>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="generated_bills.length < 1">
                                <tr>
                                    <td colspan="2">
                                        <span style="color: brown">No Bills Generated</span>
                                    </td>
                                </tr>
                            </tbody>

                            <tbody v-else>

                                <tr v-for="(item, index) in generated_bills" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.fee_name }}</td>
                                    <td>Kshs.{{ item.sub_total | formatNumber }}</td>
                                </tr>

                            </tbody>

                            <tbody>

                                <tr>
                                    <td><b></b></td>
                                    <td><b>Sub Total</b></td>
                                    <td><b>Kshs.{{ getMyBillsSubTotal(generated_bills) | formatNumber }}</b></td>
                                </tr>

                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn class="secondary text-none mt-3" text @click="createBillForEnforcementDialog = false">
                            Cancel
                        </v-btn>

                        <v-btn depressed class="accent text-none mt-3" @click="submitEnforcementApp()"
                            :loading="loadingButton">
                            Confirm
                            <v-icon right> mdi-check </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </v-row>
        <!-- Create Final Bill Dialog For Enforcement Ends Here -->

    </v-container>


</template>