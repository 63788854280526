<script setup>
import { renovateOrDemolishData, fetchAllRenovationTypes, snackBarErrorMessage, snackBarSuccessMessage, snackBarInfoFunction } from "../buildings_business_logic.js";
import BackButton from "../../utils/BackButton.vue";
import { computed, onMounted, ref, reactive } from "vue";
import apiCall from "@/utils/api.js";
import Vue from "vue";
import ViewItemSectionTitle from "../../utils/ViewItemSectionTitle.vue";
import Table from "../../utils/Table.vue";
import LabelledField from "@/components/utils/LabelledField.vue";

import {
    fieldRequired,
    validateIfNotEmpty,
    emailValid,
    isNumber,
    passwordConfirmation,
    passwordRules,
    minLength,
} from "@/utils/input_validation.js";

var numeral = require("numeral");
Vue.use(require("vue-moment"));
Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0");
});


const props = defineProps({
    building_application: {
        type: Object,
        default: null,
    },

});

const emit = defineEmits(['goBackClicked'])

const generated_bills = ref([])
const loadingButton = ref(false)
const loadingButtonDialog = ref(false)
const createBillForRenovationDialog = ref(false)
const createBillForDemolitionDialog = ref(false)
const formRenoOrDemoBuilding = ref(null)

const { snackbar_info } = snackBarInfoFunction()

const renovation_data_object = fetchAllRenovationTypes()

const renovation_data = ref([])

const renoOrDemo = ref('Renovate or Demolish Building')

const selectAction = reactive([{ id: '1', name: 'Renovate' }, { id: '2', name: 'Demolish' }])

const demolitionOrRenovationArray = computed(() => {
    let renovation_type = renovateOrDemolishData.renovation_type;
    let renovation_type_sub_category = []

    renovation_data.value.find((value, index) => {
        if (value.renovation_type == renovation_type) {
            renovation_type_sub_category = value.renovation_type_sub_category

        }

    })

    return renovation_type_sub_category;
});

const action = computed(() => {
    let myAction = renovateOrDemolishData.action
    let myActionTxt = ''

    if (myAction == null) {
        myActionTxt = 'Renovate or Demolish Building'

    }
    else if (myAction == 'Renovate') {
        myActionTxt = 'Renovate Building'

    }
    else if (myAction == 'Demolish') {
        myActionTxt = 'Demolish Building'

    }
    return myActionTxt

})

const buildingDetails = computed(() => {
    let buildingDetails = {};
    if (houseNotEmpty.value) {
        buildingDetails = getBuildingDetails(props.building_application);
    }
    return buildingDetails;
});


function getBuildingDetails(building_application) {
    const buildingDetails = {
        section1: {},
        section2: {},
    };
    buildingDetails.section1["Parcel Number"] = building_application.building.parcel_no;
    buildingDetails.section1["Subcounty"] = building_application.building.sub_counties.sub_county_name;
    buildingDetails.section1["Ward"] = building_application.building.ward.ward_name;
    buildingDetails.section1["Town"] = building_application.building.towns.town_name;
    buildingDetails.section1["Latitude"] = building_application.building.latitude;
    buildingDetails.section1["Longitude"] = building_application.building.longitude;

    buildingDetails.section2["Cost"] = building_application.building.cost;
    buildingDetails.section2["Unit of Measure"] = 'Sq Meters'
    buildingDetails.section2["Plinth Area"] = building_application.building.plinth_area
    buildingDetails.section2["Development Type"] = building_application.building.building_development_type.type_of_development
    buildingDetails.section2["Number of Floors"] = building_application.building.number_of_floors
    buildingDetails.section2["Approval Number"] = building_application.building.approval_number
    buildingDetails.section2["Physical Location/Lanmark"] = building_application.building.physical_address

    return buildingDetails;
}

function renovateOrDemolishBuilding() {
    if (formRenoOrDemoBuilding.value.validate()) {

        if (renovateOrDemolishData.action == 'Renovate') {
            if(renovateOrDemolishData.demolitionOrRenovationIds.length == 0){
                snackBarErrorMessage("Please select a checkbox for "+renovateOrDemolishData.renovation_type, 5000)
                return
            }
            
        }

        loadingButton.value = true
        if (renovateOrDemolishData.action == 'Renovate') {
            returnBuildingRenoBill()

        }
        else if (renovateOrDemolishData.action == 'Demolish') {
            returnBuildingDemoBill()

        }

    }

}

function getMyBillsSubTotal(my_bills) {
    let total = 0
    for (let i = 0; i < my_bills.length; i++) {
        total = total + parseFloat(my_bills[i].sub_total)

    }
    return total

}

function returnOwnerName() {

    if (props.building_application.building.building_owner.length > 0) {
        return props.building_application.building.building_owner[0].clients.customer_name

    } else {
        return "No Owner Found"
    }

}

function returnOwnerTel() {
    if (props.building_application.building.building_owner.length > 0) {
        return props.building_application.building.building_owner[0].clients.phone_number1

    } else {
        return "No Owner Found"
    }

}

function returnArchitectName() {
    if (props.building_application.building.building_architect.length > 0) {
        return props.building_application.building.building_architect[0].architects.first_name + ' ' + props.building_application.building.building_architect[0].architects.last_name

    } else {
        return "No Owner Found"
    }

}

function returnArchitectTel() {
    if (props.building_application.building.building_architect.length > 0) {
        return props.building_application.building.building_architect[0].architects.architect_number

    } else {
        return "No Owner Found"
    }

}

function returnPlinthArea() {
    return props.building_application.building.plinth_area

}

function returnTypeOfDevelopement() {
    return props.building_application.building.building_development_type.type_of_development

}

function returnNumOfFloors() {
    if (props.building_application.building.number_of_floors) {
        return props.building_application.building.number_of_floors

    }

    const plinth_areas_list = JSON.parse(props.building_application.building.plinth_areas_list)

    return plinth_areas_list.length

}


const smallScreenHouseDetails = computed(() => {
    const combinedHouseDetails = [
        buildingDetails.value.section1,
        buildingDetails.value.section2,
    ].reduce((previousVal, currentVal) => {
        Object.entries(currentVal).forEach(
            ([key, value]) => (previousVal[key] = value)
        );
        return previousVal;
    }, {});
    const tableHeaders = Object.keys(combinedHouseDetails).map(
        (houseDetailKey) => ({
            text: houseDetailKey,
            value: houseDetailKey,
        })
    );

    return {
        houseDetailsEnclosedInArray: [combinedHouseDetails],
        tableHeaders,
    };
});

const houseNotEmpty = computed(() => {
    return props.building_application !== null;
});

async function createRenoApplication() {
    loadingButtonDialog.value = true
    renovateOrDemolishData.building_application_id = props.building_application.id

    await apiCall({
        url: "/api/client/createRenoApp",
        data: renovateOrDemolishData,
        method: "POST",
    })
        .then((resp) => {
            //resp.status
            console.log("resp submitBuildingApplication");
            console.log(resp);
            if (resp.status == "success") {
                snackBarSuccessMessage("Building Renovation Application Created successfully", 5000)
                createBillForRenovationDialog.value = false
                
                setTimeout(function () {
                    emit('goBackClicked')
                }, 2000)


            }
            else if (resp.status == "error") {
                snackBarErrorMessage(resp.message, 5000)

            }
            else {
                snackBarErrorMessage("Error Occured, please contact admin.", 5000)
                console.log("Response Error")
                console.log(resp)

            }

            loadingButtonDialog.value = false


        })
        .catch((error) => {
            snackBarErrorMessage(error, 5000)
            loadingButtonDialog.value = false

        });

}

async function createDemoApplication() {
    loadingButtonDialog.value = true
    renovateOrDemolishData.building_application_id = props.building_application.id

    await apiCall({
        url: "/api/client/createDemoApp",
        data: renovateOrDemolishData,
        method: "POST",
    })
        .then((resp) => {
            //resp.status
            console.log("resp submitBuildingApplication");
            console.log(resp);
            if (resp.status == "success") {
                snackBarSuccessMessage("Building Demolition Created successfully", 5000)
                createBillForDemolitionDialog.value = false
                setTimeout(function () {
                    emit('goBackClicked')
                }, 2000)


            }
            else if (resp.status == "error") {
                snackBarErrorMessage(resp.message, 5000)

            }
            else {
                snackBarErrorMessage("Error Occured, please contact admin.", 5000)
                console.log("Response Error")
                console.log(resp)

            }

            loadingButtonDialog.value = false


        })
        .catch((error) => {
            snackBarErrorMessage(error, 5000)
            loadingButtonDialog.value = false

        });

}

async function returnBuildingDemoBill() {
    await apiCall({
        url: "/api/client/returnBuildingDemoBill",
        method: "GET",
    })
        .then((resp) => {
            //resp.status
            console.log("resp submitBuildingApplication");
            console.log(resp);
            if (resp.status == "success") {
                generated_bills.value = resp.bill_items
                createBillForDemolitionDialog.value = true

            }
            else if (resp.status == "error") {
                snackBarErrorMessage(resp.message, 5000)

            }
            else {
                snackBarErrorMessage("Error Occured, please contact admin.", 5000)
                console.log("Response Error")
                console.log(resp)

            }

            loadingButton.value = false


        })
        .catch((error) => {
            snackBarErrorMessage(error, 5000)
            loadingButton.value = false

        });

}

async function returnBuildingRenoBill() {
    await apiCall({
        url: "/api/client/returnBuildingRenoBill",
        method: "GET",
    })
        .then((resp) => {
            //resp.status
            console.log("resp returnBuildingRenoBill");
            console.log(resp);
            if (resp.status == "success") {
                generated_bills.value = resp.bill_items
                createBillForRenovationDialog.value = true


            }
            else if (resp.status == "error") {
                snackBarErrorMessage(resp.message, 5000)

            }
            else {
                snackBarErrorMessage("Error Occured, please contact admin.", 5000)
                console.log("Response Error")
                console.log(resp)

            }

            loadingButton.value = false

        })
        .catch((error) => {
            snackBarErrorMessage(error, 5000)
            loadingButton.value = false

        });

}


onMounted(() => {

    renovation_data_object.mutate(
        {},
        {
            onSuccess(renovation_data_object) {
                renovation_data.value = renovation_data_object

            },
        }
    );

    console.log("renovation_data_object")
    console.log(renovation_data)

})

</script>

<template>
    <v-container fluid class="pa-0">
        <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
            :color="snackbar_info.color">
            <span>{{ snackbar_info.snackbar_message }}</span>
        </v-snackbar>

        <v-row>
            <v-col cols="12" md="1">
                <BackButton @click="$emit('goBackClicked')">
                    Back To Buildings
                </BackButton>
            </v-col>
            <v-col cols="12" md="10">
                <div class="text-h6 text-center font-weight-bold primary--text">
                    Building Details
                </div>
            </v-col>
            <v-spacer />
        </v-row>

        <v-row justify-md="center">
            <v-col v-if="$mobileBreakpoint" cols="12">
                <Table :headers="smallScreenHouseDetails.tableHeaders"
                    :items="smallScreenHouseDetails.houseDetailsEnclosedInArray">
                </Table>
            </v-col>

            <template v-else>
                <v-col v-for="(houseDetailSection, sectionName, index) in buildingDetails" :key="sectionName" cols="12"
                    md="6">

                    <v-row v-if="index == 0">
                        <v-col cols="auto">
                            <ViewItemSectionTitle>Building Plan Details</ViewItemSectionTitle>
                        </v-col>
                    </v-row>

                    <v-simple-table dense class="building_application-details-table">
                        <tbody>
                            <tr v-for="(houseAttribute, attributeTitle) in houseDetailSection" :key="attributeTitle">
                                <td class="font-weight-bold">
                                    {{ attributeTitle }}
                                </td>
                                <td>
                                    <template v-if="attributeTitle !== 'Occupied'">
                                        {{ houseAttribute }}</template>
                                    <template v-else>
                                        <v-icon v-if="houseAttribute === true" color="success">mdi-check-circle</v-icon>
                                        <v-icon v-else color="error">mdi-close</v-icon>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </template>
        </v-row>


        <v-row>
            <v-col cols="auto">
                <ViewItemSectionTitle>{{ action }}</ViewItemSectionTitle>
            </v-col>
        </v-row>

        <v-form ref="formRenoOrDemoBuilding" lazy-validation>

            <v-row>
                <v-col cols="12" md="5">
                    <LabelledField label="Action" required>
                        <v-select :rules="[fieldRequired]" :items="selectAction" item-text="name" item-value="name"
                            outlined dense class="text-field-background" required
                            v-model="renovateOrDemolishData.action" hide-details="auto"></v-select>
                    </LabelledField>
                </v-col>

            </v-row>

            <template v-if="renovateOrDemolishData.action == 'Renovate'">
                <v-row>
                    <v-col cols="12" md="5">
                        <LabelledField label="Type of Renovation" required>
                            <v-select :rules="[fieldRequired]" :items="renovation_data" item-text="renovation_type"
                                item-value="renovation_type" outlined dense class="text-field-background" required
                                v-model="renovateOrDemolishData.renovation_type" hide-details="auto"></v-select>
                        </LabelledField>
                    </v-col>

                </v-row>

                <v-row v-if="renovateOrDemolishData.renovation_type">
                    <v-col>
                        <div class="mb-3 text-h5"> {{ renovateOrDemolishData.renovation_type }} <span
                                class="error--text">*</span></div>

                        <div>
                            <LabelledField label="">
                                <template v-for="(item, index) in demolitionOrRenovationArray">
                                    <v-checkbox v-model="renovateOrDemolishData.demolitionOrRenovationIds"
                                        :rules="[fieldRequired]" :value="item.id"
                                        :label="item.sub_category_name"></v-checkbox>

                                </template>

                            </LabelledField>

                        </div>

                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="5">
                        <LabelledField label="Reason for Renovating" required>
                            <v-textarea class="text-field-background" outlined dense required
                                v-model="renovateOrDemolishData.renovation_reason" :rules="[fieldRequired]"
                                hide-details="auto"></v-textarea>
                        </LabelledField>

                    </v-col>

                </v-row>

            </template>

            <template v-else-if="renovateOrDemolishData.action == 'Demolish'">
                <v-row>
                    <v-col cols="12" md="5">
                        <LabelledField label="Reason for Demolishing" required>
                            <v-textarea class="text-field-background" outlined dense required
                                v-model="renovateOrDemolishData.demolishing_reason" :rules="[fieldRequired]"
                                hide-details="auto"></v-textarea>
                        </LabelledField>

                    </v-col>

                </v-row>


            </template>


            <v-row>
                <v-col cols="12" md="2">
                    <v-btn class="text-none" color="success" type="submit" @click="renovateOrDemolishBuilding()"
                        :loading="loadingButton">{{ action }}</v-btn>

                </v-col>
            </v-row>

        </v-form>

        <!-- Adding this template tag below here is a fix for an error -->
        <template>

            <!-- Create Finall Bill Dialog For Renovation Starts Here -->
            <v-row justify="center">
                <v-dialog v-model="createBillForRenovationDialog" max-width="600">

                    <v-card>
                        <v-card-title class="primary white--text" align="center">
                            Renovation Bill Items Summary
                        </v-card-title>

                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            <b class="primary--text"> </b>
                                        </th>
                                        <th class="text-left">
                                            <b class="primary--text"> Description </b>
                                        </th>
                                        <th class="text-left">
                                            <b class="primary--text">Amount</b>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td></td>
                                        <td>Owner Name</td>
                                        <td>{{ returnOwnerName() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Owner Tel Number</td>
                                        <td>{{ returnOwnerTel() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Architect Name</td>
                                        <td>{{ returnArchitectName() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Architect Tel Number</td>
                                        <td>{{ returnArchitectTel() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Plinth Area</td>
                                        <td>{{ returnPlinthArea() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Type of Development</td>
                                        <td>{{ returnTypeOfDevelopement() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Num of Floors</td>
                                        <td>{{ returnNumOfFloors() }}</td>
                                    </tr>

                                </tbody>

                            </template>
                        </v-simple-table>

                        <v-simple-table>
                            <template v-slot:default>

                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            <b class="primary--text"> Id </b>
                                        </th>
                                        <th class="text-left">
                                            <b class="primary--text"> Description </b>
                                        </th>
                                        <th class="text-left">
                                            <b class="primary--text">Amount</b>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="generated_bills.length < 1">
                                    <tr>
                                        <td colspan="2">
                                            <span style="color: brown">No Bills Generated</span>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody v-else>

                                    <tr v-for="(item, index) in generated_bills" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.fee_name }}</td>
                                        <td>Kshs.{{ item.sub_total | formatNumber }}</td>
                                    </tr>

                                </tbody>

                                <tbody>

                                    <tr>
                                        <td><b></b></td>
                                        <td><b>Sub Total</b></td>
                                        <td><b>Kshs.{{ getMyBillsSubTotal(generated_bills) | formatNumber }}</b></td>
                                    </tr>

                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn class="secondary text-none mt-3" text @click="createBillForRenovationDialog = false">
                                Cancel
                            </v-btn>

                            <v-btn depressed class="accent text-none mt-3" @click="createRenoApplication()"
                                :loading="loadingButtonDialog">
                                Confirm
                                <v-icon right> mdi-check </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>
            </v-row>
            <!-- Create Final Bill Dialog For Renovation Ends Here -->


            <!-- Create Finall Bill Dialog For Demolition Starts Here -->
            <v-row justify="center">
                <v-dialog v-model="createBillForDemolitionDialog" max-width="600">

                    <v-card>
                        <v-card-title class="primary white--text" align="center">
                            Demolition Bill Items Summary
                        </v-card-title>

                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            <b class="primary--text"> </b>
                                        </th>
                                        <th class="text-left">
                                            <b class="primary--text"> Description </b>
                                        </th>
                                        <th class="text-left">
                                            <b class="primary--text">Amount</b>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td></td>
                                        <td>Owner Name</td>
                                        <td>{{ returnOwnerName() }}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Owner Tel Number</td>
                                        <td>{{ returnOwnerTel() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Architect Name</td>
                                        <td>{{ returnArchitectName() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Architect Tel Number</td>
                                        <td>{{ returnArchitectTel() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Plinth Area</td>
                                        <td>{{ returnPlinthArea() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Type of Development</td>
                                        <td>{{ returnTypeOfDevelopement() }}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td>Num of Floors</td>
                                        <td>{{ returnNumOfFloors() }}</td>
                                    </tr>

                                </tbody>

                            </template>
                        </v-simple-table>

                        <v-simple-table>
                            <template v-slot:default>

                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            <b class="primary--text">No.</b>
                                        </th>
                                        <th class="text-left">
                                            <b class="primary--text">Description</b>
                                        </th>
                                        <th class="text-left">
                                            <b class="primary--text">Amount</b>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="generated_bills.length < 1">
                                    <tr>
                                        <td colspan="2">
                                            <span style="color: brown">No Bills Generated</span>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody v-else>

                                    <tr v-for="(item, index) in generated_bills" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.fee_name }}</td>
                                        <td>Kshs.{{ item.sub_total | formatNumber }}</td>
                                    </tr>

                                </tbody>

                                <tbody>

                                    <tr>
                                        <td><b></b></td>
                                        <td><b>Sub Total</b></td>
                                        <td><b>Kshs.{{ getMyBillsSubTotal(generated_bills) | formatNumber }}</b></td>
                                    </tr>

                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn class="secondary text-none mt-3" text @click="createBillForDemolitionDialog = false">
                                Cancel
                            </v-btn>

                            <v-btn depressed class="accent text-none mt-3" @click="createDemoApplication()"
                                :loading="loadingButtonDialog">
                                Confirm
                                <v-icon right> mdi-check </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>
            </v-row>
            <!-- Create Final Bill Dialog For Demolition Ends Here -->

        </template>

    </v-container>


</template>